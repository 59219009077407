<script lang="ts">
  import { twMerge } from 'tailwind-merge';
  import Icon from '../icon/icon.svelte';
  import MarketoForm from './marketo-form.svelte';
  import Button from '../button/button.svelte';

  export let loading: boolean;
  export let handleSubmit: (values: Record<string, string | boolean>) => void;
  export let form: MarketoForm;

  let firstName = '';
  let lastName = '';
  let email = '';
  let company = '';
  let title = '';
  let GDPR_Consent__c = false;
  let contactUsReason = '';
  let contactUsWho = '';
</script>

<div id={form.anchor} class={twMerge('flex flex-col gap-3 w-full')}>
  <form
    on:submit|preventDefault={() =>
      handleSubmit({
        firstName,
        lastName,
        email,
        company,
        title,
        GDPR_Consent__c,
        contactUsReason,
        contactUsWho,
      })}
    class="grid grid-cols-1 gap-4 lg:grid-cols-2 items-center"
  >
    <label class="flex flex-col w-full">
      <span class="font-semibold">First Name</span>
      <input
        type="text"
        class="px-4 py-2 grow bg-space-black/50 focus:bg-space-black/80 border-2 border-subtle rounded-lg focus:outline-none focus:ring-primary focus:ring-2"
        id="FirstName"
        name="FirstName"
        bind:value={firstName}
        data-lpignore
        data-1p-ignore
        required
      />
    </label>

    <label class="flex flex-col w-full">
      <span class="font-semibold">Last Name</span>
      <input
        type="text"
        class="px-4 py-2 grow bg-space-black/50 focus:bg-space-black/80 border-2 border-subtle rounded-lg focus:outline-none focus:ring-primary focus:ring-2"
        id="LastName"
        name="LastName"
        bind:value={lastName}
        data-lpignore
        data-1p-ignore
        required
      />
    </label>

    <label class="flex flex-col w-full lg:col-span-2">
      <span class="font-semibold">Email</span>
      <input
        type="email"
        class="px-4 py-2 grow bg-space-black/50 focus:bg-space-black/80 border-2 border-subtle rounded-lg focus:outline-none focus:ring-primary focus:ring-2"
        id="Email"
        name="Email"
        bind:value={email}
        data-lpignore
        data-1p-ignore
        required
      />
    </label>

    <label class="flex flex-col w-full">
      <span class="font-semibold">Company</span>
      <input
        type="text"
        class="px-4 py-2 grow bg-space-black/50 focus:bg-space-black/80 border-2 border-subtle rounded-lg focus:outline-none focus:ring-primary focus:ring-2"
        id="Company"
        name="Company"
        bind:value={company}
        data-lpignore
        data-1p-ignore
        required
      />
    </label>

    <label class="flex flex-col w-full">
      <span class="font-semibold">Job Title</span>
      <input
        type="text"
        class="px-4 py-2 grow bg-space-black/50 focus:bg-space-black/80 border-2 border-subtle rounded-lg focus:outline-none focus:ring-primary focus:ring-2"
        id="Title"
        name="Title"
        bind:value={title}
        data-lpignore
        data-1p-ignore
        required
      />
    </label>

    <label class="flex flex-col gap-2 place-self-start w-full">
      <span class="font-semibold block">Who would you like to meet with?</span>
      <select
        id="contactUsWho"
        name="contactUsWho"
        class="px-4 py-2 bg-space-black/50 focus:bg-space-black/80 border-2 border-subtle rounded-lg focus:outline-none focus:ring-primary focus:ring-2"
        aria-required="true"
        aria-invalid="false"
        bind:value={contactUsWho}
      >
        <option>(Select One)</option>
        <option value="Executives">Executives</option>
        <option value="Architects">Architects</option>
        <option value="Sales">Sales</option>
        <option value="Partnerships">Partnerships</option>
        <option value="Other">Other</option>
      </select>
    </label>

    <label class="flex flex-col gap-2">
      <span class="font-semibold block">What would you like to discuss?</span>
      <textarea
        id="contactUsReason"
        name="contactUsReason"
        class="px-4 py-2 w-full bg-space-black/50 focus:bg-space-black/80 border-2 border-subtle rounded-lg focus:outline-none focus:ring-primary focus:ring-2"
        maxlength="5000"
        rows="4"
        bind:value={contactUsReason}
      />
    </label>

    <label class="flex items-center gap-2 place-self-start">
      <span class="font-semibold">Email Opt-In</span>
      <input
        type="checkbox"
        name="GDPR_Consent__c"
        id="GDPR_Consent__c"
        bind:checked={GDPR_Consent__c}
        class="accent-primary-600 bg-space-black"
      />
    </label>

    <p>
      By submitting this form, you are sharing your information with Temporal
      Technologies and agree to our <a
        class="font-medium underline decoration-2 underline-offset-2 decoration-ultraviolet"
        href="https://temporal.io/global-privacy-policy"
        target="_blank">Privacy Policy</a
      >.
    </p>

    <Button type="submit" class="place-self-end lg:col-span-2">
      {#if loading}
        <Icon name="spinner" class="animate-spin" />
      {:else}
        Submit request
      {/if}
    </Button>
  </form>
  <slot />
</div>

<style lang="postcss">
  label span {
    @apply inline-flex items-center gap-2;
  }

  label span:has(+ input[required]):after {
    @apply content-[''] bg-red-500 rounded-full h-2 w-2 block;
  }
</style>
