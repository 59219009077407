<script lang="ts">
  import Button from '../button/button.svelte';
  import Grid from '$lib/layout/grid/grid.svelte';
  import Box from '$lib/layout/grid/box.svelte';
  import Input from '@temporalio/ui/holocene/input/input.svelte';
  import Select from '@temporalio/ui/holocene/select/select.svelte';
  import Checkbox from '@temporalio/ui/holocene/checkbox.svelte';
  import { countries } from '$lib/utilities/countries';
  import Option from '@temporalio/ui/holocene/select/option.svelte';
  import Text from '../text/text.svelte';
  import type { MarketoForm } from '$lib/contentful/models/marketo-form';
  import Icon from '../icon/icon.svelte';
  import type { GoogleAdsFields } from '$lib/services/marketo';

  export let handleSubmit: (values: GoogleAdsFields) => void;
  export let loading: boolean;
  export let form: MarketoForm;

  $: ({ title, body, entityId, anchor } = form);

  const values: GoogleAdsFields = {
    firstName: '',
    lastName: '',
    email: '',
    company: '',
    country: '',
    GDPR_Consent__c: false,
  };
</script>

<Grid id={anchor} as="div" layout="columns">
  <Box class="flex flex-col gap-4" span={4} as="div">
    {#if title}
      <Text fieldId="title" {entityId} variant="display-68" as="h3"
        >{title}</Text
      >
    {/if}
    {#if body}
      <Text
        class="text-indigo-100"
        fieldId="body"
        {entityId}
        variant="body-16"
        as="p">{body}</Text
      >
    {/if}
  </Box>
  <Box span={4} offset={8} as="div">
    <form
      class="flex flex-col gap-4"
      on:submit|preventDefault={() => {
        handleSubmit(values);
      }}
    >
      <Input
        label="First name"
        id="first-name"
        bind:value={values.firstName}
        required
      />
      <Input
        label="Last name"
        id="last-name"
        bind:value={values.lastName}
        required
      />
      <Input
        label="Email address"
        id="email-address"
        bind:value={values.email}
        required
      />
      <Input
        label="Company"
        id="company"
        bind:value={values.company}
        required
      />
      <Select label="Country" id="country" bind:value={values.country} required>
        {#each countries as option}
          <Option value={option}>{option}</Option>
        {/each}
      </Select>
      <Checkbox
        label="Email opt-in"
        id="email-opt-in"
        bind:checked={values.GDPR_Consent__c}
      />
      <p class="text-xs">
        By submitting this form, you are sharing your information with Temporal
        Technologies and agree to our <a
          class="pulsar-link"
          target="_blank"
          href="https://docs.temporal.io/privacy-policy/">Privacy Policy</a
        >.
      </p>
      <Button type="submit">
        {#if loading}
          <Icon name="spinner" class="animate-spin" />
        {:else}
          Submit request
        {/if}
      </Button>
    </form>
    <slot />
  </Box>
</Grid>
