<script lang="ts">
  import { twMerge } from 'tailwind-merge';
  import Icon from '../icon/icon.svelte';
  import MarketoForm from './marketo-form.svelte';

  let email = '';
  export let loading: boolean;
  export let handleSubmit: (values: Record<string, string>) => void;
  export let form: MarketoForm;
</script>

<div id={form.anchor} class={twMerge('flex flex-col gap-3', $$props.class)}>
  <form on:submit|preventDefault={() => handleSubmit({ email })}>
    <label for="merch-discount-code-signup">{form.title}</label>
    <div
      class="flex flex-row h-10 rounded-lg focus-within:ring-4 focus-within:ring-ultraviolet/70"
    >
      <input
        type="email"
        class="px-4 grow bg-transparent border-2 border-subtle rounded-l-lg focus:outline-none"
        id="merch-discount-code-signup"
        data-lpignore
        data-1p-ignore
        bind:value={email}
        required
      />
      <button type="submit" class="bg-gradient-ultraviolet rounded-r-lg px-4">
        {#if loading}
          <Icon name="spinner" class="animate-spin" />
        {:else}
          Submit
        {/if}
      </button>
    </div>
  </form>
  <slot />
</div>
